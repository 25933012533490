import React from 'react'

import Container from '../container/container'
import styles from './section.module.scss'

const About = () => {
  return (
    <Container>
      <div className={styles.section}>
        <h3 className={styles.title}>
          Our mission is to make the cyber world a safer 
          place and to detangle the technical from the 
          basics of risk management in a new domain. 
        </h3>
        <div className={styles.divider} />
        <div className={styles.text}>
          <p>
            Our team synthesizes experience from the insurance industry, financial 
            risk management, US Dept. of Defense Cyber Operations, and 
            organizational behavior expertise to help executives and investment 
            funds separate technical requirements from quantification of cyber risk 
            and investment process.
          </p>
          <p>
            We believe new technology and fancy jargon should not negate known 
            truths about how to identify, quantify, and reduce risk to an organization.
          </p>
        </div>
      </div>
    </Container>
  )
}
 
export default About
