import React from 'react'
import { Link } from 'gatsby'

import Container from '../container/container'
import styles from './hero.module.scss'

const Hero = () => {
  return (
    <div className={styles.hero}>
      <Container>
        <h1 className={styles.title}>
          Matching Cyber Defenses to Evolving Risks in New Terrain
        </h1>
        <Link to='/services' className={styles.button}>Our Services &amp; Approach &raquo;</Link>
      </Container>
    </div>
  )
}
 
export default Hero
