import React from 'react'
import { Link } from 'gatsby'

import Container from '../container/container'
import styles from './section.module.scss'
import wrapperStyles from './wrapper.module.scss'

const Contact = () => {
  return (
    <div className={wrapperStyles.wrapper}>
      <Container>
        <div className={`${styles.section} ${styles.rightAlign}`}>
          <h3 className={styles.title}>
            Contact Us
          </h3>
          <div className={styles.divider} />
          <div className={styles.text}>
            <p>
              Learn more about our cyber risk assessments, 
              cyber defense investment rationale, security 
              reporting standards, or organizational 
              governance best practices. 
            </p>
            <Link className={styles.button} to="/contact">
              Contact Us Today &raquo;
            </Link>
          </div>
        </div>
     </Container>
    </div>
  )
}
 
export default Contact
