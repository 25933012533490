import React, { useEffect } from 'react'

import Layout from '../components/layout/layout'
import Hero from '../components/hero/hero'
import About from '../components/info-sections/about'
import Contact from '../components/info-sections/contact'

const IndexPage = () => {

  // useEffect(() => {
  //   getContacts()
  // }, [])
  
  // const getContacts = async () => {
  //   fetch('http://localhost:3000/get_contacts')
  //     .then(response => {
  //       return response.text();
  //     })
  //     .then(data => {
  //       console.log('contacts', data);
  //     });
  // }

  return (
    <Layout title="Home">
      <Hero />
      <About />
      <Contact />
    </Layout>
  )
}

export default IndexPage
